import { omit, mapKeys } from "lodash";

import {
  CREATE_APPOINTMENT,
  EDIT_APPOINTMENT,
  FETCH_APPOINTMENTS,
  FETCH_APPOINTMENT,
  DELETE_APPOINTMENT,
  CANCEL_APPOINTMENT,
  LIST_CLINIC_APPOINTMENTS,
} from "actions/appointments/types";

/**
 * @param state
 * @param action
 */
export default function appointments(
  state: Object = {},
  action: Object
): Object {
  switch (action.type) {
    case FETCH_APPOINTMENTS: {
      const arrayofAppoinments = action.payload.data;
      const objectsOfAppoinments =  { ...mapKeys(arrayofAppoinments, "id") };
      const reversedAppointments =
      Object.keys(objectsOfAppoinments).sort((a, b) => b - a).reduce((acc, key) => {
        if(arrayofAppoinments[key-1]){
          acc[key] = arrayofAppoinments[key-1];
        }
        return acc;
      }, {});
      return { ...reversedAppointments };
    }
    case CREATE_APPOINTMENT:
    case FETCH_APPOINTMENT:
    case EDIT_APPOINTMENT:
    case CANCEL_APPOINTMENT:
      return { ...state, [action.payload.data.id]: action.payload.data };
    case DELETE_APPOINTMENT:
      return omit(state, action.payload);
    case LIST_CLINIC_APPOINTMENTS:
      return { ...state, clinic_appointments: action.payload.data };
    default:
      return state;
  }
}
